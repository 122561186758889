<template>
  <v-container>
    <v-layout row>
      <v-flex md6 offset-sm3>
        <div>
          <div v-if="preview == null">
            <v-btn class="mt-8" color="primary" outlined block @click="click1"
              >Bild auswählen</v-btn
            >
            <input
              type="file"
              ref="input1"
              style="display: none"
              @change="previewImage"
              accept="image/*"
            />
          </div>

          <div v-if="preview != null">
            <img class="preview mt-4" :src="preview" />
            <br />
          </div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md6 offset-sm3 class="text-center mt-4">
        <v-select
          :items="folders"
          v-model="selectedGallery"
          item-value="value"
          item-text="name"
          label="Gallerie"
          filled
        ></v-select>
      </v-flex>
      <v-flex md6 offset-sm3 class="text-center">
        <v-text-field filled v-model="name" label="Name des Bildes">
        </v-text-field>
      </v-flex>
      <v-flex md6 offset-sm3 class="text-center">
        <v-progress-linear
          v-if="uploadValue !== 0"
          :value="uploadValue"
          height="10"
          striped
          color="accent"
        ></v-progress-linear>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md6 offset-sm3 class="text-center">
        <v-btn
          color="primary"
          block
          depressed
          :loading="uploadValue !== 0"
          :disabled="uploadValue !== 0"
          @click="uploadImage"
        >
          Hochladen</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import firebase from "firebase";
import { db } from "@/main";
export default {
  data() {
    return {
      name: "",
      uploadValue: 0,
      imageData: null,
      preview: null,
      selectedGallery: "others",
      folders: []
    };
  },
  methods: {
    create(url) {
      const post = {
        image: url,
        name: this.name,
        gallery: this.selectedGallery
      };
      db.collection("clients")
        .doc("koebbe")
        .collection("images")
        .add(post)
        .then(response => {
          console.log(response);
          this.uploadValue = 0;
          this.preview = null;
          this.name = "";
        })
        .catch(err => {
          console.log(err);
        });
    },
    click1() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.imageData = event.target.files[0];
      const files = event.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
      this.preview = event;
      // this.onUpload();
    },
    uploadImage() {
      const storageRef = firebase
        .storage()
        .ref(`koebbe/${this.selectedGallery}/${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.create(url);
          });
        }
      );
    },
    fetchFolders: function() {
      // set db refrences
      const koebbeRef = db.collection("clients").doc("koebbe");

      // get participant from usergroup
      koebbeRef.get().then(doc => {
        this.folders = doc.data().folders;
        this.selectedFolder = doc.data().folders[0];
        // this.fetchMedia(doc.data().folders[0]);
        this.$store.dispatch("fetchImages", doc.data().folders[0].value);
      });
    }
  },
  created() {
    // this.fetchMedia();
    this.fetchFolders();
  }
};
</script>
<style lang="sass">
.preview
  width: 100%
</style>
